.logo {
    width: 120px;
    height: 31px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px 28px 16px 0;
    float: left;
  }
  
    .logo {
      background: rgba(255, 255, 255, 0.3);
    }
  